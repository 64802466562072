import { doc, onSnapshot } from 'firebase/firestore'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { db } from 'services/firebase'

import { useAuth } from './AuthContext'

const AdminUserDataContext = createContext<any>(null)

export function useAdminUserData() {
	return useContext(AdminUserDataContext)
}

export function AdminUserDataProvider(props: { children: any }) {
	const { currentUser } = useAuth()
	const [adminUserData, setAdminUserData] = useState<AdminUserDataDoc | null | undefined>(undefined)

	useEffect(() => {
		const fetchData = () => {
			getAdminUserDataRef.current()
		}
		return fetchData()
	}, [currentUser])

	const getAdminUserDataRef = useRef(() => {
		// 
	})

	getAdminUserDataRef.current = () => {
		if (currentUser) {
			onSnapshot(doc(db, `adminUsers/${currentUser.uid}`), (snapshot: any) => {
				const data: any = snapshot.data()
				setAdminUserData(data)
			}, (_error) => {
				setAdminUserData(null)
			})
		}
	}
	const value = {
		adminUserData
	}

	return (
		<AdminUserDataContext.Provider value={value}>
			{props.children}
		</AdminUserDataContext.Provider>
	)
}

export interface AdminUserDataDoc {
	isAdmin: boolean,
	isInternalUser: boolean,
	isStatsAccess: boolean,
	isDeveloper: boolean,
	isUsersAccess: boolean,
	isManageUsersAccess: boolean,
	isUserRequestsAccess: boolean,
}

