import AdminDataLoading from 'components/AdminDataLoading'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAdminUserData } from 'services/contexts/AdminUserDataContext'
import { useAuth } from 'services/contexts/AuthContext'
const PrivateRoute = () => {
	const { currentUser } = useAuth()
	const { adminUserData } = useAdminUserData()
	if (currentUser) {
		if (adminUserData?.isAdmin ?? false) {
			if (adminUserData?.isInternalUser ?? false) {
				return <Outlet />
			} else {
				return <Navigate to="/dashboard" />
			}
		} else {
			return <AdminDataLoading />
		}
	}
	else {
		return <Navigate to="/login" />
	}
}
export default PrivateRoute